import { useEffect, useState } from 'react';

import { enablePersonalizedArticleList } from '@hubcms/brand';
import { CookData, NewsArticleData } from '@hubcms/domain-cook';
import { useAuth } from '@hubcms/data-access-auth';
import { getPageType, getViewId, getSetGlitrCookieId } from '@hubcms/utils-tracking';
import { isFirstItemWithKey, mapRecord } from '@hubcms/utils-browser';
import { useCook } from '@hubcms/data-access-cook';
import { useEnv } from '@hubcms/data-access-env';
import { RecommendationsResponse } from '@hubcms/domain-capitan';
import { useDidomiConsent } from '@hubcms/utils-didomi';
import { TeaserDataOrList } from '@hubcms/domain-teaser';
import { StoryblockAreas } from '@hubcms/domain-storyblock';
import { isSameTeaserDataOrListRecord } from '@hubcms/utils-teaser';

import { getRecommendationData } from '../utils/get-recommendation-data';
import { mapPersonalizedArticleLists } from '../utils/map-personalized-article-list';
import { requiredPersonalizationConsentIds } from '../utils/required-personalization-consent-ids';
import { PersonalizedList, isPersonalizedList } from '../domain/personalized-list';
import { getArticleIdsOnBody } from '../utils/getArticleIdsOnBody';

const getPersonalizedArticleListsFromAreas = (teasers: StoryblockAreas<TeaserDataOrList>): PersonalizedList[] => {
  return Object.values(teasers).flat().filter(isPersonalizedList).filter(isFirstItemWithKey('id'));
};

const EMPTY_RECOMMENDATIONS_RESPONSE: RecommendationsResponse = {
  requestId: '',
  lists: [],
  modelVersion: '',
  recommender: '',
};

const usePersonalizedArticleListLoaderEnabled = (
  teaserAreas: StoryblockAreas<TeaserDataOrList>,
): StoryblockAreas<TeaserDataOrList> => {
  const [areas, setAreas] = useState(mapRecord(teaserAreas, mapPersonalizedArticleLists(EMPTY_RECOMMENDATIONS_RESPONSE, true)));
  const { user, isLoading: userIsLoading } = useAuth();
  const cookData: CookData<NewsArticleData> = useCook();
  const env = useEnv();
  const { hasDidomiConsent, isDidomiReady } = useDidomiConsent(requiredPersonalizationConsentIds);

  const identityAccountId = user?.sub ?? null;
  const pageType = getPageType(cookData);
  const articleId = cookData.context?.id ?? null;
  const recommendationsApiEndpoint = env.NEXT_PUBLIC_RECOMMENDATIONS_API_ENDPOINT;

  useEffect(() => {
    setAreas(currentAreas => (isSameTeaserDataOrListRecord(currentAreas, teaserAreas) ? currentAreas : teaserAreas));
  }, [teaserAreas]);

  useEffect(() => {
    let mounted = true;
    const personalizedArticleLists = getPersonalizedArticleListsFromAreas(teaserAreas);

    const fetchData = async () => {
      const response = await getRecommendationData({
        apiEndpoint: recommendationsApiEndpoint,
        personalizedArticleLists,
        identityAccountId,
        pageType,
        articleId,
        clientId: getSetGlitrCookieId(),
        viewId: getViewId(),
        excludes: document?.body ? getArticleIdsOnBody(document.body) : [],
      });

      if (mounted) {
        setAreas(mapRecord(teaserAreas, mapPersonalizedArticleLists(response, false)));
      }
    };

    if (personalizedArticleLists.length > 0 && !userIsLoading && isDidomiReady) {
      if (hasDidomiConsent) {
        fetchData();
      } else if (mounted) {
        setAreas(mapRecord(teaserAreas, mapPersonalizedArticleLists(EMPTY_RECOMMENDATIONS_RESPONSE, false)));
      }
    }

    return () => {
      mounted = false;
    };
  }, [
    teaserAreas,
    userIsLoading,
    identityAccountId,
    articleId,
    pageType,
    recommendationsApiEndpoint,
    hasDidomiConsent,
    isDidomiReady,
  ]);

  return areas;
};

const usePersonalizedArticleListDisabled = (teaserAreas: StoryblockAreas<TeaserDataOrList>) => teaserAreas;

export const usePersonalizedArticleListLoader = enablePersonalizedArticleList
  ? usePersonalizedArticleListLoaderEnabled
  : usePersonalizedArticleListDisabled;
