import {
  AudioVisualArticle,
  ElementsFragment,
  ImageFieldsFragment,
  isElementWithChildren,
  isElementWithRelation,
} from '@hubcms/domain-cook';
import {
  HeroMediaStoryElement,
  ImageSource,
  StoryElement,
  StoryElementType,
  createImageData,
} from '@hubcms/domain-story-elements';
import { isValidStoryElement } from '@hubcms/utils-story-elements';

import { StoryDataWithElements } from '../domain/story-data';

const VALID_HERO_MEDIA_STORY_ELEMENT_TYPES: StoryElementType[] = ['gallery', 'image', 'audiovisual'];
const HERO_MEDIA_COOK_ELEMENT_TYPES: StoryElementType[] = ['gallery', 'image', 'hero_media'];

type TeaserImage = AudioVisualArticle['context']['teaserImage'];

export function findHeroMediaId(articleData: StoryDataWithElements): string | null {
  const element = articleData.elements.find(
    element => HERO_MEDIA_COOK_ELEMENT_TYPES.includes(element.type as StoryElementType) && elementHasContent(element),
  );
  return element?.id ?? null;
}

function elementHasContent(element: ElementsFragment) {
  if (isElementWithRelation(element) && element.relation !== null) {
    return true;
  }
  if (isElementWithChildren(element) && element.children.length > 0) {
    return true;
  }
  return element.fields.length > 0;
}

export function validateHeroMedia(storyElement: StoryElement | undefined) {
  if (!isValidStoryElement<HeroMediaStoryElement>(VALID_HERO_MEDIA_STORY_ELEMENT_TYPES)(storyElement)) {
    return null;
  }
  return storyElement;
}

export function createStoryHeroMediaFromTeaserImage(teaserImage: TeaserImage): HeroMediaStoryElement | null {
  if (!teaserImage?.length || !teaserImage[0]?.content?.fields) {
    return null;
  }

  return {
    id: 'audiovisual-hero-media',
    type: 'image',
    data: createImageData({
      ...createImageSource(teaserImage[0].content.fields.sixteenNine),
      alt: teaserImage[0].caption,
      caption: teaserImage[0].caption,
      orientation: 'SIXTEEN_NINE',
      thumbUrl: teaserImage[0].content.fields.oneOne.href_full,
      alternatives: {
        fourFive: createImageSource(teaserImage[0].content.fields.fourFive),
        fourThree: createImageSource(teaserImage[0].content.fields.fourThree),
        oneOne: createImageSource(teaserImage[0].content.fields.oneOne),
        sixteenNine: createImageSource(teaserImage[0].content.fields.sixteenNine),
        threeTwo: createImageSource(teaserImage[0].content.fields.threeTwo),
        twentyoneNine: createImageSource(teaserImage[0].content.fields.twentyoneNine),
      },
    }),
  };
}

function createImageSource(source: ImageFieldsFragment): ImageSource {
  return {
    url: source.href_full,
    originalWidth: source.width,
    originalHeight: source.height,
  };
}
